.last-transaction {
  z-index: 100;
  position: fixed;
  top: 90px;
  left: 30px;
  border: 1px solid #aaa;
  border-radius: 5px;
  background-color: #1d2127;
  color: #fff;
  padding: 5px 15px;
  box-shadow: 2px 2px 2px 2px rgb(0, 0, 0, 0.12);
}
