.countr-app {
}

.countr-app-header {
  background-color: #1d2127;
  padding: 10px;
  color: white;
  box-shadow: 2px 5px 15px -1px grey;
  position: fixed;
  width: 100%;
  height: 44px;
  z-index: 1001;
}

.countr-app-logo {
  height: 40px;
}

.countr-app-root {
  text-align: center;
  background-color: #eee;
  position: absolute;
  top: 64px;
  width: 100%;
  height: calc(100% - 70px);
}
