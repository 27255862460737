.login {
}

.login-input-field {
  width: 300px;
}

.login-btn {
  width: 300px;
}

.remember-login-div {
  position: relative;
  right: 88px;
}

.remember-login {
  position: relative;
  top: 2px;
  cursor: pointer;
}
